<template>
  <v-container fluid>
    <PageTitle title="Setting" />
    <v-card>
      <v-card-title>Ubah Password</v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="8">
            <v-form>
              <v-text-field
                label="Current Password"
                outlined
                dense
              />
              <v-text-field
                label="New Password"
                outlined
                dense
              />
              <v-text-field
                label="Confirm Password"
                outlined
                dense
              />
              <v-btn elevation="2" color="zipay">
                Save changes
              </v-btn>
            </v-form>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import PageTitle from "@/components/molecules/PageTitle.vue";

export default {
  components: {
    PageTitle
  },
  data() {
    return {

    }
  }
};
</script>
